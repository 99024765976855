import React, {
    useEffect,
    useState,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import {
    DialogContent,
    makeStyles,
    TableCell,
} from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import TableHead from '@material-ui/core/TableHead';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import '../../../assets/css/custom/collapse-show-educate.css';
import {
    Card,
    CardHeader,
    CardBody,
    Collapse,
} from 'reactstrap';
import CircularIndeterminate
    from '../../../components/Loading/CircularIndeterminate';
import {
    getIntern,
    getInternCourses,
    showIntern,
    getLoading,
} from '../../../features/user/userSlice';
import {internScoreType, getScoreOfType} from '../../../configs/internCofig';

const useStyles = makeStyles({
    closeButton: {
        position: 'absolute',
        zIndex: 1000,
        color: '#525f7f',
        right: '7px',
        top: '4px',
    },
    editInternModal: {
        padding: '0 2rem',
    },
    numberPracticeDay: {
        marginRight: '2.1rem',
    },
    dayData: {
        marginTop: '-1.5rem',
    },
    internDateInfor: {
        width: '300px',
        minWidth: '300px',
    },
    commentScore: {
        display: '-webkit-box',
        WebkitLineClamp: '6',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        whiteSpace: 'normal',
        maxWidth: '200px',
        minWidth: '200px',
        color: '#838383',
    },

    border_b_o: {
        borderBottom: '1px solid #fff !important',
    },
    border_b: {
        borderBottom: '1px solid #cad1d7 !important',
    },
    valignCenter: {
        verticalAlign: 'baseline !important',
    },
    tableColumn: {
        paddingLeft: '0.5rem !important',
        paddingRight: '0.5rem !important',
    },

    selectReactStyle: {
        marginTop: '15px',
        marginBottom: '10px',
        width: '15rem',
    },
    courseTitle: {
        paddingLeft: '0 !important',
        width: 'max-content',
        paddingTop: '0',
        paddingBottom: '0',
        border: '0',
    },
    show_point_card: {
        boxShadow: 'none !important',
    },
});

export default function ShowScoreModal(props) {
    const classes = useStyles();
    const internShowId = props.internShowId;
    const dispatch = useDispatch();

    const user = useSelector(getIntern);
    const courses = useSelector(getInternCourses);
    const loading = useSelector(getLoading);

    useEffect(() => {
        async function fetchInterInformation(userId) {
            dispatch(showIntern(userId));
        }
        if (props.showScoreModal && internShowId) {
            fetchInterInformation(internShowId).then(() => {});
        }
    }, [props.showScoreModal]);
    const [openedCollapses, setOpenedCollapses] = useState([]);
    useEffect(() => {
        if (courses && courses.length > 0) {
            setOpenedCollapses([`collapseCourse${courses[0].id}`]);
        }
    }, [courses]);
    const handleClose = async () => {
        props.setShowScoreModal(false);
    };

    const getInternClassStatus = status => {
        if (/Chậm/.test(status)) {
            return 'text-danger';
        } else if (/Đúng/.test(status)) {
            return 'text-success';
        } else {
            return 'text-warning';
        }
    };

    const collapsesToggle = collapse => {
        if (openedCollapses.includes(collapse)) {
            setOpenedCollapses([]);
        } else {
            setOpenedCollapses([collapse]);
        }
    };

    const renderScores = (subjects) => {
        let result = '';
        if (subjects && subjects?.length > 0) {
            result = subjects.map((subject) => {
                let scores = subject.score_details;
                return (<TableRow key={subject.id}>
                        <TableCell
                            className={`pl-0 align-bottom ${classes.valignCenter} ${classes.tableColumn}`}
                            component="th"
                            align="left">
                            <span>{subject.name}</span>
                        </TableCell>
                        <TableCell
                            className={`pl-0 align-bottom ${classes.valignCenter} ${classes.tableColumn}`}
                            align="center">
                            <span>{subject.limited_date ?? 0}</span>
                        </TableCell>

                        <TableCell align="center"
                                   className={`${classes.tableColumn}`}>
                            {subject.is_technical
                            ? (getScoreOfType(scores,
                                internScoreType.theory)?.['value'] ?? 'NA')
                            : (subject.is_has_quiz ? (subject.score ?? 'NA') : '')}
                        </TableCell>
                        {
                            subject.is_technical
                                ? (<>
                                    <TableCell align="center"
                                               className={`${classes.tableColumn}`}>
                                        {(getScoreOfType(scores,
                                                internScoreType.practice)?.['value'] ??
                                            'NA')}
                                    </TableCell>
                                    <TableCell align="center"
                                               className={`${classes.tableColumn}`}>

                                        {getScoreOfType(scores,
                                                internScoreType.attitude)?.['value'] ??
                                            'NA'}
                                    </TableCell>
                                    <TableCell align="left"
                                               className={`${classes.tableColumn}`}>
                                        <p className={`${classes.commentScore} mb-0`}>

                                            {getScoreOfType(scores,
                                                    internScoreType.hard_work)?.['comment'] ??
                                                'NA'}
                                        </p>
                                    </TableCell>
                                    <TableCell align="left"
                                               className={`${classes.tableColumn}`}>
                                        <p className={`${classes.commentScore} mb-0`}>

                                            {getScoreOfType(scores,
                                                    internScoreType.discipline)?.['comment'] ??
                                                'NA'}
                                        </p>
                                    </TableCell>
                                    <TableCell align="left"
                                               className={`${classes.tableColumn}`}>
                                        <p className={`${classes.commentScore} mb-0`}>

                                            {getScoreOfType(scores,
                                                    internScoreType.honest)?.['comment'] ??
                                                'NA'}
                                        </p>
                                    </TableCell>
                                    <TableCell className={`${classes.tableColumn}`}
                                               align="left">
                                        <p className={`${classes.commentScore} mb-0`}>

                                            {getScoreOfType(scores,
                                                    internScoreType.communicate)?.['comment'] ??
                                                'NA'}
                                        </p>
                                    </TableCell>
                                </>) : null
                        }

                        <TableCell colSpan={subject.is_technical ? 1 : 7}
                                   className={`pr-0 align-bottom ${classes.valignCenter} ${classes.tableColumn}`}
                                   align="right">
                            {subject?.score ?
                                parseFloat(subject?.score).toFixed(1)
                                : ''}
                            {subject?.score || subject.user_done_soft_skill_not_quiz
                                ? (
                                    subject?.is_pass || subject.user_done_soft_skill_not_quiz
                                        ? <CheckIcon style={{color: 'green'}} />
                                        : <CloseIcon style={{color: 'red'}} />
                                )
                                : 'NA'
                            }
                        </TableCell>
                    </TableRow>
                );
            });
        }

        return result;
    };
    const renderCourses = courses => {
        let result = '';
        if (courses && courses.length > 0) {
            result = courses.map(item => {
                return (
                    <div key={`course-item${item.id}`} className="accordion">
                        <Card
                            className={`card-plain ${classes.show_point_card}`}>
                            <CardHeader
                                role="tab"
                                onClick={() => collapsesToggle(
                                    `collapseCourse${item.id}`)}
                                className={`ml-0 ${classes.courseTitle}`}
                                aria-expanded={openedCollapses.includes(
                                    `collapseCourse${item.id}`,
                                )}
                            >
                                <span className={`mr-4`}>{item.name}</span>
                            </CardHeader>
                            <Collapse
                                role="tabpanel"
                                isOpen={openedCollapses.includes(
                                    `collapseCourse${item.id}`)}
                            >
                                <CardBody>
                                    <TableContainer>
                                        <Table
                                            className={'w-100 table table-hover'}
                                            aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        className={`pl-0 pb-0 ${classes.border_b_o} ${classes.tableColumn}`}
                                                        align="left">Môn
                                                        học</TableCell>
                                                    <TableCell
                                                        className={`pl-0 pb-0 ${classes.border_b_o} ${classes.tableColumn}`}
                                                        align="left">Thời gian
                                                        học</TableCell>
                                                    <TableCell colSpan={3}
                                                               className={`pl-0 pb-0 ${classes.border_b_o} ${classes.tableColumn}`}
                                                               align="center">Điểm</TableCell>
                                                    <TableCell colSpan={4}
                                                               className={`pl-0 pb-0 ${classes.border_b_o} ${classes.tableColumn}`}
                                                               align="center">Nhận
                                                        xét</TableCell>
                                                    <TableCell
                                                        className={`pr-0 pb-0 ${classes.border_b_o}  ${classes.tableColumn}`}
                                                        align="right">Tổng&nbsp;</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pl-0 pt-0 `}
                                                        align="left"></TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pl-0 pt-0 `}
                                                        align="center">(Ngày)</TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pt-0`}
                                                        align="center">Lý
                                                        thuyết</TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pt-0 `}
                                                        align="center">Thực
                                                        hành&nbsp;</TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pt-0`}
                                                        align="center">Thái
                                                        độ&nbsp;</TableCell>

                                                    <TableCell
                                                        className={`${classes.tableColumn} pt-0`}
                                                        align="center">Chăm
                                                        chỉ</TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pt-0`}
                                                        align="center">Kỉ
                                                        luật &nbsp;</TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pt-0`}
                                                        align="center">Trung
                                                        thực &nbsp;</TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pt-0`}
                                                        align="center">Giao
                                                        tiếp&nbsp;</TableCell>
                                                    <TableCell
                                                        className={`${classes.tableColumn} pr-0 pt-0`}
                                                        align="right">&nbsp;</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {renderScores(item.subjects)}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </CardBody>
                            </Collapse>
                        </Card>
                    </div>);
            });
        }
        return result;
    };

    return (
        <div>
            {loading ? (<CircularIndeterminate />
            ) : ''}
            <Dialog
                maxWidth={'xl'}
                className={`${classes.editModalDialog} modalEditIntern `}
                open={props.showScoreModal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <IconButton onClick={handleClose} size={'medium'}
                                className={classes.closeButton}>
                        <CloseIcon fontSize={'large'}
                                   style={{width: '24px', height: '24px'}} />
                    </IconButton>
                    <h2>Kết quả học tập</h2>
                    <div>
                        <p className={'mb-0'}>Họ tên: {user.name}</p>
                        <Grid className={'d-flex flex-row'}>
                            <Grid
                                className={`d-flex flex-column ${classes.internDateInfor}`}>
                                <p className={'mb-0'}>Email: {user.email}</p>
                                <p className={'mb-0'}>Ngày bắt
                                    đầu: {user.start_date}</p>
                                <p className={'mb-0'}>
                                    Ngày kết thúc: {user?.end_date ??
                                    'Chưa kết thúc'}</p>
                                <p className={'mb-0'}>
                                    Thời lượng thực
                                    tập: {user?.percent_worker}%</p>
                                <p className={'mb-0'}>
                                    Công nghệ: {user?.technology}</p>
                                <p className={'mb-0'}>
                                    Bộ phận: {user.division ?? 'NA'}</p>

                            </Grid>

                            <Grid className={'d-flex flex-column'}>
                                <p className={'mb-0'}>Số ngày
                                    nghỉ: {user.day_off}</p>
                                <p className={'mb-0'}>Số ngày thực
                                    tập: {user.date_training_total}</p>
                                <p className={'mb-0 text-ovf1'}>Kế hoạch tuyển
                                    dụng: {user?.recruitment_name
                                        ? user?.recruitment_name
                                        : 'NA'}
                                </p>
                                <p className={'mb-0'}>
                                    Ngày lên team dự
                                    tính: {user?.predict_end_date}</p>
                                <p className={'mb-0'}>
                                    Tiến độ:
                                    <span className={getInternClassStatus(
                                        user?.intern_training_status_str)}> {user?.intern_training_status_str}</span>
                                </p>
                                <p className={'mb-0'}>
                                    Vị trí: {user?.position ?? 'NA'}</p>
                            </Grid>
                        </Grid>
                        <Grid className={`mt-2`}>
                            <span className={''}>Khóa học:</span>
                        </Grid>
                        {renderCourses(courses)}
                        <Grid className={'d-flex justify-content-between'}>
                            <p className={'mb-0'}>Tổng kết:</p>
                            <p className={'mb-0'}>
                                <b>{user.avg_score ?? 'NA'}</b>
                            </p>
                        </Grid>


                        <Grid className={'d-flex justify-content-between'}>
                            <p className={'mb-0'}>Kết quả thực tập:</p>
                            <p className={'mb-0 font-weight-bold'}>
                                {user?.is_pass == null
                                    ? (<b>NA </b>)
                                    : (user?.is_pass ? <span
                                                className={'text-success'}>Pass </span>
                                            : <span
                                                className={'text-danger'}> Fail </span>
                                    )
                                }
                            </p>
                        </Grid>
                        {
                            user.is_pass == true
                                ? (<Grid
                                    className={'d-flex justify-content-between'}>
                                    <p>Đánh giá trên team:</p>
                                    <p className={classes.teamScore} align="right">
                                        {user?.score_in_team ?? 'NA'}
                                    </p>
                                </Grid>)
                                : ''
                        }

                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

ShowScoreModal.propTypes = {
    setShowScoreModal: PropTypes.func,
    showScoreModal: PropTypes.bool,
    internShowId: PropTypes.number,
};